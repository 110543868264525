// 填写用户昵称
import React, {Component} from 'react';
import { Modal ,Button,Toast,InputItem,Icon} from 'antd-mobile';
import icon_logo from '../res/img/logo.png';

import {inject, observer} from "mobx-react/index";
@inject("myEnrollStore", "enrollStore")
@observer
class NICKNAME extends Component {
    state = {
        visibleDialog: false,
        uname:'',
        upic:'',
        nameList: ['匿名用户', '无昵称', '微信用户'],
        defaultPic: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220823/c103431f90ef62d823b4b412ebe2de5d.png',
    };
    componentDidMount(){
        this.props.onRef(this);          
    }
    render(){
        return (
			<Modal visible={this.state.visibleDialog} animationType="slide-up" popup className='upload-name-modal'>
				<div className='header-panel'>
					<img className='logo-img' src={icon_logo} />
					<span>报名工具&nbsp;申请</span>
				</div>
				<h3 className='warn-text'>获取你的昵称、头像</h3>
                <div className='flex-between-box cursor input-box'>
					<span>头像</span>
                    <div className='right-upload'>
                        <img className='upload-img' src={this.state.upic || this.state.defaultPic} onClick={()=>{
                            React.$wx.uploadImage().then(d=>{                                
                                if(d.sta == 0){                               
                                    this.setState({upic: d.data.url});
                                }                   
                            })
                        }} />            
                        <Icon type="right" className='right-icon' />
                    </div>         
				</div>				
				<div className='flex-between-box cursor input-box'>
					<span>昵称</span>
					<InputItem type="text" placeholder='点击输入' value={this.state.uname} onChange={(value)=>{ this.setState({uname:value.trim()})}} clear moneyKeyboardAlign="left" />                       
				</div>               
				<div className='footer-panel'>
					<Button className='operate-btn' onClick={()=>{
						this.setState({visibleDialog:false});
					}}>取消</Button>
					<Button className='operate-btn sure-btn' onClick={()=>{this.changeName()}}>确定</Button>
				</div>  
			</Modal>
		)
    };
    // 获取用户信息
    getDetail(){
		return new Promise(resolve=>{
			this.props.enrollStore.getUserInfo().then(res=>{
				let {userInfo} = this.props.enrollStore	                
				resolve(userInfo.uname);        
			})
		})        
    };
    async checkUserName(){
        let userName = await this.getDetail();	
        return new Promise(resolve=>{
            let uname = localStorage.getItem('uname');            
            if(this.props.needLogin == 0 && (!userName || this.state.nameList.indexOf(userName) != -1 ) && (!uname || this.state.nameList.indexOf(uname) != -1)){             
                this.setState({visibleDialog:true});
                return;
            }else{              
                resolve();
            }	  
        })			    
    };
    changeName(){         
		Toast.loading();
		let new_length = this.state.uname.replace(/[^\u0000-\u00ff]/g,"aa").length;
        if(this.state.uname.length < 2){
            Toast.info('昵称最少为2字符',2)
            return
        }else if(new_length > 32){
            Toast.info('昵称最多为32字符',2)
            return
        }else if(this.state.nameList.indexOf(this.state.uname) != -1){
            Toast.info('请修改昵称后再提交',2)
            return
        }
        let params = {
            uname:this.state.uname,          
        }
        if(this.state.upic){
            params.upic = this.state.upic;
        }
        this.props.myEnrollStore.editUserMessage(params,'post').then(res=>{
			Toast.hide();
            if(res.sta == 0){
				this.setState({visibleDialog:false});
                localStorage.setItem('uname',this.state.uname);
				Toast.success('提交成功');				             				       
            }else{
                Toast.fail(res.msg,2);				
            }
        })
	};
}

export default NICKNAME;
