// 填写用户昵称
import React, {Component} from 'react';
import { Modal ,Button,Toast,InputItem} from 'antd-mobile';
import icon_logo from '../res/img/logo.png';
import {inject, observer} from "mobx-react/index";
import WxApi from '../common/wxapi';
@inject("myEnrollStore", "enrollStore")
@observer
class NICKNAME extends Component {
    state = {
        visibleDialog: false,
        uname:'',
        showModal:true,
        errorMsg:''
    };
    componentDidMount(){
        this.props.onRef(this);          
    }
    render(){
        const miniBtnStyle = {
            width:'100%',
            height:'100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
        }
        return (
            <Modal
            visible={this.state.visibleDialog}
            closable={true}
            maskClosable={false}
            transparent			
            onClose={()=>{this.setState({visibleDialog:false})}}
            title="温馨提示"				
            >
            <div className='warn-modal-panel'>
                <p className='address-text'>你还未设置昵称，无法操作，请到个人中心->修改昵称</p>
               <div className='flex-between-box'>
                    <Button className='operate-btn' onClick={()=>{this.setState({visibleDialog:false})}}>取消</Button>
                    <Button type="primary" className='operate-btn jump-btn' onClick={()=>{
                        Toast.info('仅手机端微信浏览器支持前往小程序');
                    }}>去修改
                    {
                        WxApi.isMobile() && WxApi.isWeixn() ? 
                        <wx-open-launch-weapp style={miniBtnStyle} id="wx-edit-btn" username="gh_ae6449501a82" path="/other/personalinfo/personalinfo.html">                              
                            <script type="text/wxtag-template">
                                <div style={{width:'100%',height:'40px'}}></div>
                            </script>
                        </wx-open-launch-weapp> : ''
                    }
                    </Button>
               </div>
            </div>
        </Modal>
		)
    };
    // 获取用户信息
    getDetail(){
		return new Promise(resolve=>{
            let {userInfo} = this.props.enrollStore;         
            if(!userInfo || (!userInfo.uname || userInfo.uname == '匿名用户' || userInfo.uname == '微信用户' || userInfo.uname == '无昵称')){ //避免每次都请求这个接口
                this.props.enrollStore.getUserInfo().then(res=>{                                                    
                    resolve(this.props.enrollStore.userInfo.uname);        
                })
            }else{
                resolve(userInfo.uname); 
            }			
		})        
    };
    // 校验是否有昵称
    async checkUserName(){
        let userName = await this.getDetail();	
        return new Promise(resolve=>{
            if((!userName || userName == '匿名用户' || userName == '微信用户' || userName == '无昵称')){             
                this.setState({visibleDialog:true});
                return;
            }else{              
                resolve();
            }	  
        })			    
    };
}

export default NICKNAME;
