import config from "../config"
import HttpHelper from "./HttpHelper";
import HttpRequest from "./HttpRequest";
import {myEnrollStore} from '../store/enroll/myEnroll'
import React from "react";
import {Toast} from "antd-mobile/lib/index";
export default class WxApi {

    static jumping = false;
    static component_appid = "";

    static getToken() {
		const localToken = localStorage.getItem(config.wx_token_key)
		let isComplaint = window.location.href.indexOf('/complaint') != -1 || window.location.href.indexOf('/signature') != -1; //投诉或涂写签名页面，则取传过来的token
		let token = WxApi.getQueryString("tk");
        if(token && (!WxApi.isWeixn() || isComplaint)){
			localStorage.setItem(config.wx_token_key,token)
			sessionStorage.removeItem('init_zhini');
			return token;
		}else if (localToken) {
            return localToken;
        }else {
            return null;
        }
    }

    static setToken(value) {
        config.TOKEN = value;
		localStorage.setItem(config.wx_token_key, value);
		localStorage.removeItem('uname');
		// sessionStorage.setItem(config.wx_token_key, value);
    }

	//同步token存商户appid 确保token和appid对应
	static setAppid(value){
		config.MCHID = value;
		localStorage.setItem('MCH_ID', value);
	}
	
	static getMchId(){
		const mch_id = localStorage.getItem('MCH_ID');
		if(!mch_id){
			return "";
		}else{
			return mch_id;
		}
	}
	
	
    static removeToken() {
		config.TOKEN = ''
        localStorage.removeItem(config.wx_token_key);		
		React.$wx.webLogin()
    }

    static getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null){
        	var parm = unescape(r[2]);
        	if(parm.indexOf(" ") >-1){
        		parm = parm.replace(/\s+/g,"");
        	}
        	return parm;
        } else return null; 
    }

    static urlencode(str) {
        str = (str + '').toString();
        return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
    }

    static getAppid(){
		const appid = WxApi.getQueryString("appid");
		if(appid){
			return appid;
		}else if(config.bm_domain == true){
			return config.bmAppid;
		}else{
			return config.appid;
		}      
    }

    static jumpWxAuth() { 
 	//  WxApi.setToken('a96f02ec72ff443695a7e67e9ca73b6c');
    //  return;
        if (WxApi.jumping) {
            return;
        }
		if(!WxApi.isWeixn())return;
        /*
        * 获取TOKEN
        *    有TOKEN   获取接口调用权限
        *    无TOKEN   跳转授权
        *        授权取到CODE
        *             找后端要CODE
        *        授权么有取到CODE 或者后端返回失败
        *             跳错误页面
        * */

        WxApi.jumping = true;
        const currentUrl = window.location.href;
        let url = WxApi.urlencode(currentUrl);
        const code = WxApi.getQueryString("code");		
        if (!code) {
            const authUrl = config.wx_auth_url.replace("#{{appid}}", WxApi.getAppid()).replace("#{{url}}", url);
            WxApi.jumping = false;
            window.location.href = authUrl;
        }

        else {			
            let data = { code: code };
            if (config.bm_domain) {
                data = {code: code, source: 'h5'};
            }
            return HttpHelper.post(config.api_wx_getToken, data).then(d => {
                if (d.status == 200 && d.data) {
                    WxApi.jumping = false;
                    let data = d.data;
                    if (data.sta == -1) {
                        window.location.hash = "/error/0";
                    }
                    else {
                        WxApi.setToken(data.data.access_token);
                    	let _url = window.location.href;
    					let baseUrl = _url.split("?");
    					let routerArr = _url.split('#');
    					let routerPath = routerArr[routerArr.length -1];						
						HttpHelper.get(config.api_get_userInfo, {params:{access_token: data.data.access_token}}).then(d=>{
							let res = d.data 
							if(res.sta == 0){
								if(window.location.href.indexOf('/complaint') == -1){
									window._ZHINI({
										action:"init",  // 初始化开始指令；
										client_id:res.data.openid   // 用户的唯一标识，由您自行生成，必填且不能为无效字符，否则不生效;
									});	
								}								
							}						 
							window.location.href = routerPath;
						})							 				

						// Toast.success('登录成功');
						// setTimeout(() => {						
						// 	window.location.href = routerPath;
						// }, 1000);						
						// let eid = WxApi.getQueryString("eid");
    					// let referer = WxApi.getQueryString("referer");
						// if(routerPath.indexOf("auth") >-1){
						// 	window.location.href = baseUrl[0] +"#"+routerPath;
						// }else{
						// 	window.location.href = baseUrl[0] + "?eid="+eid+"&referer="+referer+"#"+routerPath;
						// }    
						WxApi.configApi();					    					
                    }
                }
            }, () => {
                WxApi.jumping = false;
                window.location.hash = "/error/0";
            });
        }
    }

    static configApi(success, error) {
        const bm = 'baominggongju.com';
        let url = WxApi.urlencode(window.location.href);
        if (url.indexOf(bm) > -1) {
            config.bm_domain = true;  // 域名是baominggongju.com时，修改此变量值
        }
        HttpHelper.post(config.api_wx_sign, {
            access_token: config.TOKEN,
            url: window.location.href.split('#')[0],
            source: config.bm_domain == true ? 'h5' : '',
        }).then(function (response) {
            if (response.status === 200) {
                window.wx.config({
                    debug: false,
                    appId: WxApi.getAppid(),
                    timestamp: response.data.data.timestamp,
                    nonceStr: response.data.data.noncestr,
                    signature: response.data.data.sign,
                    jsApiList: [
                        'getLocation',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'chooseWXPay',
                        'previewImage',
                        'openLocation',
                        'chooseImage',
                        'uploadImage',
						'scanQRCode',
						// 'openAddress',
                    ],
					openTagList: ['wx-open-launch-weapp','wx-open-subscribe'] 
                });
                window.wx.ready(function () {					
					console.log("----------注册api成功-")
                    success && success();
                });
                window.wx.error(function (res) {					
					console.log("----------注册api失败-： ",res)
                    error && error(res);
                });
            }
        }).catch(function (errors) {
            console.log('errors');
        })
    }


    static setShare(title, desc, link, imgUrl,authorizer_appid) {
		if(authorizer_appid){
			WxApi.configApiBusiness(() => {
	            window.wx.onMenuShareTimeline({
	                title: title, // 分享标题
	                desc: desc,
	                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	                imgUrl: imgUrl, // 分享图标
	                success: function () {
	                    console.log("分享朋友圈配置");
	                    console.log(title, desc, link, imgUrl);
	                }
	            });
	            window.wx.onMenuShareAppMessage({
	                title: title, // 分享标题
	                desc: desc,
	                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	                imgUrl: imgUrl, // 分享图标
	                success: function () {
	                    // 设置成功
	                    console.log("分享好友配置");
	                    console.log(title, desc, link, imgUrl);
	
	                }
	            });
	        });
		}else{
			 WxApi.configApi(() => {
	            window.wx.onMenuShareTimeline({
	                title: title, // 分享标题
	                desc: desc,
	                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	                imgUrl: imgUrl, // 分享图标
	                success: function () {
	                    console.log("分享朋友圈配置");
	                    console.log(title, desc, link, imgUrl);
	                }
	            });
	            window.wx.onMenuShareAppMessage({
	                title: title, // 分享标题
	                desc: desc,
	                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	                imgUrl: imgUrl, // 分享图标
	                success: function () {
	                    // 设置成功
	                    console.log("分享好友配置");
	                    console.log(title, desc, link, imgUrl);
	
	                }
	            });
	        });
		}
    }
    
    static replaceParamVal(paramName,replaceWith) {
	    var oUrl = window.location.href.toString();
	    var re=eval('/('+ paramName+'=)([^&]*)/gi');
	    var nUrl = oUrl.replace(re,paramName+'='+replaceWith);
//	    this.location = nUrl;
	    return nUrl
//	　　window.location.href=nUrl
	}
    
    
    static jumpBusinessAuth(component_appid){
	// 	WxApi.setToken('a6bddcd0d83f45a6bed187eda5e4307b'); 
    //  return;
		if (WxApi.jumping) {
            return;
        }
		WxApi.component_appid = component_appid;
		WxApi.jumping = true;
    	const currentUrl = window.location.href;
        let url = WxApi.urlencode(currentUrl);
        const code = WxApi.getQueryString("code");
    	if (!code) {
    		const authUrl = config.business_auth_url.replace("#{{appid}}", component_appid).replace("#{{url}}", url).replace("#{{business_id}}",config.businessId);
            WxApi.jumping = false;
            window.location.href = authUrl;
    		
    	}else {
    		let data = {
    			code: code ,
    			appid: component_appid
    		};
    		return HttpRequest.post(config.api_business_getToken, data).then(d => {
                if (d.status == 200 && d.data) {
                    WxApi.jumping = false;
                    let data = d.data;
                    if (data.sta == -1) {
                        window.location.hash = "/error/0";
                    }
                    else {
                        WxApi.setToken(data.data.access_token);
                        WxApi.setAppid(component_appid);
                        let _url = window.location.href;
    					let baseUrl = _url.split("?");
    					let routerArr = _url.split('#');
    					let routerPath = routerArr[routerArr.length -1];
    					
    					let eid = WxApi.getQueryString("eid");
    					let referer = WxApi.getQueryString("referer");
    					window.location.href = baseUrl[0] + "?eid="+eid+"&referer="+referer+"#"+routerPath;
    					WxApi.configApiBusiness();
                    }
                }
            }, () => {
                WxApi.jumping = false;
                window.location.hash = "/error/0";
            });
    	}
    	
    }
    
    
     static configApiBusiness(success, error) {
     	const bm = 'baominggongju.com';
        let url = WxApi.urlencode(window.location.href);
        if (url.indexOf(bm) > -1) {
            config.bm_domain = true;  // 域名是baominggongju.com时，修改此变量值
        }
     	
     	HttpRequest.post(config.api_business_sign, {
            access_token: config.TOKEN,
            url: window.location.href.split('#')[0],
        }).then(function (response) {
            if (response.status === 200) {
                window.wx.config({
                    debug: false,
                    appId: sessionStorage.getItem("authorizer_appid"),
                    timestamp: response.data.data.timestamp,
                    nonceStr: response.data.data.noncestr,
                    signature: response.data.data.sign,
                    jsApiList: [
                        'getLocation',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'chooseWXPay',
                        'previewImage',
                        'openLocation',
                        'chooseImage',
                        'uploadImage',
						'scanQRCode',
						// 'openAddress',
                        // 'onMenuShareTimeline',
                        // 'onMenuShareAppMessage',
                    ]
                });
                window.wx.ready(function () {
                    success && success();
                });
                window.wx.error(function (res) {
                    error && error(res);
                });
            }
        }).catch(function (errors) {
            console.log('errors');
        })
     	
     }
    
    //h5订阅消息
    static jumpNotice(scene,source){
	    let url = WxApi.urlencode(config.url_notice);
	    let reserved = config.EID + "&source="+source;
		const noticeUrl = config.wx_notice_url.replace("#{{appid}}", WxApi.getAppid()).replace("#{{url}}", url).replace("#{{temp_id}}",config.temp_id).replace("#{{scene}}", scene).replace("#{{reserved}}", reserved);
		
		window.location.href = noticeUrl;
    }
    
    static urlencode(str){
		str = (str + '').toString();
	    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
	}
	
	
	static getIdCardInfo(cardNo) {
	  /*
	      isTrue: false, // 身份证号是否有效。默认为 false
	      year: null,// 出生年。默认为null
	      month: null,// 出生月。默认为null
	      day: null,// 出生日。默认为null
	      isMale: false,// 是否为男性。默认false
	      isFemale: false // 是否为女性。默认false
	  */
	  let [isTrue, year, month, day, isMale, isFemale] = [false, null, null, null, false, false];
	  let card_type = '';
	  let age = '';
	  if (cardNo) {
		const isEven = n => n % 2 === 0;
		cardNo = cardNo.trim();
		if (18 === cardNo.length) {
			
			[year, month, day] = [+cardNo.substring(6, 10), +cardNo.substring(10, 12), +cardNo.substring(12, 14)];
			const birthday = new Date(year, month - 1, day);
			if (birthday.getFullYear() === year && birthday.getMonth() === month - 1 && birthday.getDate() === day) {
				const Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1];// 加权因子
				const Y = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2];// 身份证验证位值.10代表X
				// 验证校验位
				  let _cardNo = Array.from(cardNo); // reset: let _cardNo = [...cardNo];
				  if (_cardNo[17].toLowerCase() === 'x') {
					  _cardNo[17] = 10;// 将最后位为x的验证码替换为10方便后续操作
				  }
				  let sum = 0; // 声明加权求和变量
				  for (let i = 0; i < 17; i++) {
					  sum += Wi[i] * _cardNo[i];// 加权求和
				  }
				  const i = sum % 11;// 得到验证码所位置
				  if (+_cardNo[17] === Y[i]) {
					  isTrue = true;
					  // 大陆身份证 显示性别和年龄
					  age = this.getAgeFromIdCard(cardNo)
					  card_type = this.getGenderFromIdCard(cardNo) + '/' + age + '岁'
					  isEven(+cardNo.substring(16, 17)) ? isFemale = true : isMale = true;
				  }
				
			  
			}
		}
		else if(cardNo.length === 11 || cardNo.length === 9){
		  var kongMacaoOld = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/;
		  var kongMacaoNew = /^[HMhm]{1}([0-9]{8})$/;
		  if(kongMacaoOld.test(cardNo) === true || kongMacaoNew.test(cardNo) === true){
			isTrue = true;
			card_type = '港澳通行证';
		  }else {
			isTrue = false;
		  }
		}
		else if(cardNo.length === 8 || cardNo.length === 10){
		  if (/^\d{8}$/.test(cardNo)) { // 校验是否为8位数字  台胞证
			isTrue = true;
			card_type = '台胞证';
		  }else{
			var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let str = cardNo;
			if (str.length < 8) isTrue = false;
	
			if ((str.charAt(str.length-3) == '(' || str.charAt(str.length-3) == '（') && (str.charAt(str.length-1) == ')' || str.charAt(str.length-1) == '）'))
			str = str.substring(0, str.length - 3) + str.charAt(str.length -2);
			str = str.toUpperCase();
			var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
			var matchArray = str.match(hkidPat);
			if (matchArray == null || !matchArray || matchArray.length <= 0){
			  isTrue = false;
			} else if(matchArray && matchArray.length > 0){
			  var charPart = '';
			  if(matchArray[1]){
				charPart = matchArray[1];
			  }
	
			  var numPart = '';
			  if(matchArray[2]){
				numPart = matchArray[2];
			  }
	
			  var checkDigit = '';
			  if(matchArray[3]){
				checkDigit = matchArray[3]
			  }
	
			  var checkSum = 0;
			  if (charPart.length == 2) {
			  checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
			  checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
			  } else {
			  checkSum += 9 * 36;
			  checkSum += 8 * (10 + strValidChars.indexOf(charPart));
			  }
			  for (var i = 0, j = 7; i < numPart.length; i++, j--)
			  checkSum += j * numPart.charAt(i);
			  var remaining = checkSum % 11;
			  var verify = remaining == 0 ? 0 : 11 - remaining;
			  
			  if(verify == checkDigit || (verify == 10 && checkDigit == 'A')){
				isTrue = true;
			  }else isTrue = false;
			}else{
			  isTrue = false;
			}
		  }  		 
		}
	}
	
	  if (!isTrue) [year, month, day, isMale, isFemale] = [];
	  return {isTrue, year, month, day, isMale, isFemale,card_type,age};
	};

	// 根据身份证号码判断年龄
	static getAgeFromIdCard(idCard) {
		// 提取出生日期部分
		var birthdate = '';
		if (idCard.length === 18) {
			birthdate = idCard.substring(6, 14);
		}
		// 解析出生日期
		var year = parseInt(birthdate.substring(0, 4), 10);
		var month = parseInt(birthdate.substring(4, 6), 10);
		var day = parseInt(birthdate.substring(6, 8), 10);
	
		// 获取当前日期
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear();
		var currentMonth = currentDate.getMonth() + 1;
		var currentDay = currentDate.getDate();
	
		// 计算年龄
		var age = currentYear - year;
		if (currentMonth < month || (currentMonth === month && currentDay < day)) {
			age--;
		}
		return age;
	}

	// 根据身份证号码判断性别
	static getGenderFromIdCard(idCard) {
		// 获取身份证号码中的第17位数字
		var digit = parseInt(idCard.charAt(16), 10);
		// 判断性别
		if (digit % 2 === 0) {
			return '女';
		} else {
			return '男';
		}
	}
  
	
	static checkEmail(email){
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if(reg.test(email)){
			return true;
		}else{
			return false;
		}
		
	}
	
	static isWeixn(){  
		var ua = navigator.userAgent.toLowerCase();  
		if(ua.match(/MicroMessenger/i)=="micromessenger") {  
			var wechatInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
			console.log(wechatInfo)
			return true;  
		} else {  
			return false;  
		}  
	}
	
	static isMobile() {
	    var ua = navigator.userAgent.toLowerCase();
	    var bIsIpad = ua.match(/ipad/i) == "ipad";
	    var bIsIphoneOs = ua.match(/iphone os/i) == "iphone os";
	    var bIsMidp = ua.match(/midp/i) == "midp";
	    var bIsUc7 = ua.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
	    var bIsUc = ua.match(/ucweb/i) == "ucweb";
	    var bIsAndroid = ua.match(/android/i) == "android";
	    var bIsCE = ua.match(/windows ce/i) == "windows ce";
	    var bIsWM = ua.match(/windows mobile/i) == "windows mobile";
	    if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) ){
	        return false;
	    } else return true;
	}

	//其他app内置浏览器
	static isOtherEnvironments(){
		const userAgent = navigator.userAgent.toLowerCase();	
		const isIPhone = /iphone|ipad|ipod/i.test(userAgent);		 		 
		if(userAgent.indexOf('alipayclient') !== -1){ //支付宝
			return true;
		}else if(userAgent.indexOf("yunshanfu") !== -1){//云闪付
			return true;
		}else if(/mailapp\/(\d+\.\d+\.\d+)/i.test(userAgent) && isIPhone){ //QQ邮箱客户端
			return true;
		}	
		return false;	 
	}

	static isUnionPay() {
		var ua = navigator.userAgent.toLowerCase();
		if(ua.match(/UnionPay/i)=="unionpay") {
			return true;
		} else {
			return false;
		}
	}
	
	// 获取时区
	static getTimeZone(){
		let zone = new Date().getTimezoneOffset()
		let zone_time = Math.abs(new Date().getTimezoneOffset())*60
		let zone_str = this.getTimeString(zone_time)
		if(zone == -480){
			zone_str = ''
		}else if (zone > 0) {
		  zone_str = "-" + zone_str
		} else {
		  zone_str = "+" + zone_str
		}
		return zone_str
		 
	}

	static getTimeString(timeInSeconds) {
		var hour = Math.floor(timeInSeconds / 60 / 60);
		if (hour < 10) {
		hour = '0' + hour;
		}
		var minute = Math.floor((timeInSeconds - hour * 60 * 60) / 60);
		if (minute < 10) {
		minute = '0' + minute;
		}
		return hour + ':' + minute;
	}
	
	static setSeatInfo(choiceSeat,items){ 
		let {seatInfo, setSeatInfo} = myEnrollStore;
	    let selectInfo = [];
		let {positionX,positionY} = this.getPosition(items);
	    if(choiceSeat && choiceSeat.length > 0){
	      choiceSeat.map(item => {
	        let newChoice = {}
	        newChoice.rowIndex = item[0];
	        newChoice.columnIndex = item[1];
			 
			//下面是锁定不参与编号相关逻辑
			let coordinateX = WxApi.clone(positionX);
			let lockData = [];
			if(items && items.lock && items.lock.length){ //编辑
				lockData = items.lock;			 
				setSeatInfo('');		 			 
			}else if(seatInfo && seatInfo.lock){ //新增
				lockData = seatInfo.lock;			 	 			  			 
			}
			 
			for(let a = 0; a < lockData.length; a++){ //主要就是，在x轴数据被锁定的地方，push空字符串，这样排列顺序就变了
				if(lockData[a][0] == item[0]){			 
					coordinateX[lockData[a][1]-1] = '';
				}
			}
			 
			if(seatInfo.column_sort == 1 || (items && items.column_sort == 1)){ // 倒序
				let i = coordinateX.length - 1;
				for(let a = coordinateX.length - 1; a >= 0; a--){
					if(coordinateX[a]){
						coordinateX[a] = positionX[i]
						i --;
					}					
				}				
			}else{ //正序
				let i = 0;
				for(let a = 0; a < coordinateX.length; a++){
					if(coordinateX[a]){
						coordinateX[a] = positionX[i]
						i ++;
					}
				}
			}
		
			if((items && (items.column_type == 5 || items.row_type == 5)) || (seatInfo && (seatInfo.column_type == 5 || seatInfo.row_type == 5))){
				newChoice.seatText = positionY[item[0]-1] + coordinateX[item[1]-1];
			}else{
				let y = positionY[item[0]-1];
				let x = coordinateX[item[1]-1];
				if(seatInfo.row_type == 0){ //选择不显示纵坐标时，则纵坐标等于横坐标
					y = x;
				}
				newChoice.seatText = y + '排' + x + '座';
			}
	        selectInfo.push(newChoice);
	      })
	    }else{
	      selectInfo = [];
	    }
		return selectInfo;
	}

	// 处理横纵坐标 
	static getPosition(items){
		let {seatInfo,rowList} = myEnrollStore
		let letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q','R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD','AE','AF','AG','AH','AI','AJ','AK','AL',
		'AM','AN','AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ','BA','BB','BC','BD','BE','BF','BG','BH','BI','BJ','BK','BL','BM','BN']//字母坐标
		let numberArr = [],positionX=[],positionY=[],xLength=0,yLength=0;
		if(items){ //编辑时
			xLength = items.column
			yLength = items.row
			seatInfo = items
		}else{
			xLength = rowList[0]?rowList[0].length:0
			yLength = rowList.length
		}
		for(let i=1; i<61; i++ ){
			let num = i;
			if(i< 10){
				num = "0" + i
			}
			numberArr.push(num+'')
		}

		// X轴数据  column_type 0 无 1 数字 2数字+排 3字母 4字母+排
		if(seatInfo["column_type"]){
			let selectLetter = letter.filter((item,index)=>{return index < xLength})
			let selectNumber = numberArr.filter((item,index)=>{return index < xLength})
			if(seatInfo["column_type"] == 5){ //自定义
				positionX = seatInfo.column_name
			}else if(seatInfo["column_type"]>2){ //字母
				if(seatInfo['column_sort'] == 1)selectLetter.reverse()  //1倒序 0正序
				positionX = selectLetter
			}else{  //数字
				if(seatInfo['column_sort'] == 1)selectNumber.reverse()
				positionX = selectNumber
			}
		}

		// Y轴数据 row_type 0 无 1 数字 2数字+排 3字母 4字母+排
		if(seatInfo["row_type"]){
			let selectLetter = letter.filter((item,index)=>{return index < yLength})
			let selectNumber = numberArr.filter((item,index)=>{return index < yLength})
			if(seatInfo["row_type"] == 5){
				positionY = seatInfo.row_name
			}else if(seatInfo["row_type"]>2){ //字母
				if(seatInfo['row_sort'] == 1)selectLetter.reverse()  //1倒序 0正序
				positionY = selectLetter
			}else{  //数字
				if(seatInfo['row_sort'] == 1)selectNumber.reverse()
				positionY = selectNumber
			}
		}
		return {positionX,positionY}
	}
	
	static addNum(arg1,arg2) { //小数相加避免精度丢失
		var r1, r2, m;
		try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
		try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
		m = Math.pow(10, Math.max(r1, r2))
		return (arg1 * m + arg2 * m) / m
	}

	static subtrNum(arg1,arg2){ //小数相减避免精度丢失
		var r1,r2,m,n; 
		try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
		try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
		m=Math.pow(10,Math.max(r1,r2)); 
		n=(r1>=r2)?r1:r2; 
		return((arg1*m-arg2*m)/m).toFixed(n); 
	}

	static accMul(arg1,arg2){ //小数相乘
		var m=0,s1=arg1.toString(),s2=arg2.toString(); 	
		try{m+=s1.split(".")[1].length}catch(e){} 	
		try{m+=s2.split(".")[1].length}catch(e){} 	
		return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)  
	} 

	// 处理图片地址为本地图片
	static changeLocalImage(arr){
		if(!arr)return;
		return arr.map(item=>{
			if(item.banner && item.banner.indexOf('/images/bg') > -1){
				item.banner = 'https://cdn-xcxcustom.weiyoubot.cn/20210705/b6049009536ef7dfd84b72c38281b7ae.png'
			}
			return item
		})
	}

	// 复制功能
	static copeText(text) {
		// 数字没有 .length 不能执行selectText 需要转化成字符串
		const textString = text.toString()
		let input = document.querySelector('#copy-input')
		if (!input) {
		  input = document.createElement('input')
		  input.id = 'copy-input'
		  input.readOnly = 'readOnly'   // 防止ios聚焦触发键盘事件
		  input.style.position = 'absolute'
		  input.style.top = '0px'
		  input.style.left = '-1000px'
		  input.style.zIndex = '-1000'
		  document.body.appendChild(input)
		}
		input.value = textString
		// ios必须先选中文字且不支持 input.select();
		WxApi.selectText(input, 0, textString.length)
		if (document.execCommand('copy')) {
		  document.execCommand('copy')
		} else {
		  console.log('不兼容')
		}
		input.blur()
	  }
	   // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
	  // 选择文本。createTextRange(setSelectionRange)是input方法
	   static selectText(textbox, startIndex, stopIndex) {
		  if (textbox.createTextRange) { // ie
			const range = textbox.createTextRange()
			range.collapse(true)
			range.moveStart('character', startIndex)// 起始光标
			range.moveEnd('character', stopIndex - startIndex)// 结束光标
			range.select()// 不兼容苹果
		  } else { // firefox/chrome
			textbox.setSelectionRange(startIndex, stopIndex)
			textbox.focus()
		  }
		}

		/* 自动填写功能
			auto_write 是否支持自动填写
			base_info  基础信息中的
			formItems  当前自动填写的数据
			tmpItems  同上 暂不知道区别
			extra_info  当前用户的所有自动填写数据
			ext_mark_key  老数据的自动填写内容
		*/
		static initCommonInfo(detail,userInfo){
			let {auto_write} = detail
			let {extra_info,ext_mark_key,base_info} = userInfo
			let formItems = this.clone(detail.req_info)
			let tmpItems = this.clone(detail.req_info)

			if(auto_write != 1){ //禁止自动填写
				return;
			}

			let isSet = false,viewSet=false
			if(detail.req_info && detail.req_info.length>0){
				isSet = detail.req_info.some(item=>item.hasSet)
				viewSet = detail.req_info.some(item=>item.viewSet)
			}
			
			for (let i = 0; i < tmpItems.length; i++) {
				let item = tmpItems[i];
				if (item.field_key == 1 && item.field_name == "姓名" && ext_mark_key.indexOf('name') < 0) {
				  item.field_value = base_info.name;
				  formItems[i].field_value = base_info.name;
				}
				
				if ((item.field_type == 13 || item.field_type == 0 || item.field_type == '0' || item.field_type == 7 || item.field_type == 1) && item.field_name == "手机号" && ext_mark_key.indexOf('phone') < 0) {
				  if(userInfo.account_phone){
					item.field_value = userInfo.account_phone;
					formItems[i].field_value = userInfo.account_phone;
				  }else{
					item.field_value = base_info.phone;
					formItems[i].field_value = base_info.phone;
				  }					  		 
				}
				if ((item.field_key == 8 || item.field_key == 3) && item.field_name == "微信号" && ext_mark_key.indexOf('wx_no') < 0) {
				  item.field_value = base_info.wx_no;
				  formItems[i].field_value = base_info.wx_no;
				}
				if (item.field_type == 18 && ext_mark_key.indexOf('id_no') < 0) {
				  item.field_value = base_info.id_no;
				  formItems[i].field_value = base_info.id_no;
				}
				if (item.field_key == 9 && item.field_name == "邮箱" && ext_mark_key.indexOf('email') < 0) {
				  item.field_value = base_info.email;
				  formItems[i].field_value = base_info.email;
				}
				if ((item.field_key == 5 || item.field_key == 4) && item.field_name == "地址" && ext_mark_key.indexOf('address') < 0) {
				  item.field_value = base_info.address;
				  formItems[i].field_value = base_info.address;
				}

				let arr = [0,1,4,5,7,8,13,18,10,11]
				for (var j=0;j<extra_info.length;j++) {
				  if (extra_info[j].name.indexOf(item.field_name) != -1 && item.hidden != 1 && arr.indexOf(item.field_type) !=-1) {

					let optionsData = [];
					if(Array.isArray(item.new_options) && item.new_options.length){
						optionsData = item.new_options;
					}else if(Array.isArray(item.options) && item.options.length){
						optionsData = item.options;
					}
					//单选按钮,有跳题和显示设置、逻辑设置的,不支持默认填写				 
					if(item.field_type == 4 && !isSet && !viewSet && (!detail.req_logic || !detail.req_logic.length)){ //单选						 
						for(let o = 0; o < optionsData.length; o++){						
						if(optionsData[o].value == extra_info[j].value || optionsData[o].text == extra_info[j].value){						
						  	optionsData[o].checked = 1;
							item.field_value = extra_info[j].value;
							formItems[i].field_value = extra_info[j].value;
						}
					  }
					}else if(item.field_type== 5 && (!detail.req_logic || !detail.req_logic.length)){//多选						 						
						for(let o = 0; o < optionsData.length; o++){									
							if(optionsData[o].value == extra_info[j].value || optionsData[o] == extra_info[j].value){						
								optionsData[o].checked = 1;
								if(Array.isArray(item.field_value) && Array.isArray(formItems[i].field_value)){
									item.field_value.push(extra_info[j].value);
									formItems[i].field_value.push(extra_info[j].value);	
								}else{
									item.field_value = [extra_info[j].value];
									formItems[i].field_value = [extra_info[j].value];
								}												
							}
						}						
					}else if(item.field_type == 10){ //下拉框												
						for(let k = 0; k < optionsData.length; k++){	
							console.log(optionsData[k] , extra_info[j].value)		
						  if(optionsData[k].value == extra_info[j].value || optionsData[k] == extra_info[j].value){
							item.index = k;
							formItems[i].index = k;
							item.field_value = extra_info[j].value;
							formItems[i].field_value = extra_info[j].value;							
						  }
						}												
					}else if(!((item.field_type == 4 || item.field_type == 5) && (isSet || viewSet || (detail.req_logic && detail.req_logic.length)))){ //只要不是单选且跳题、逻辑设置的，都走这个						
						item.field_value = extra_info[j].value;
						formItems[i].field_value = extra_info[j].value;						
					}				 

					if(item.field_type == 13 && userInfo.account_phone){  //手机号取系统手机号
						item.field_value = userInfo.account_phone;
						formItems[i].field_value = userInfo.account_phone;
					}
				  }

				}
			}
			
			return formItems
	}

	// 缩略图
	static getAutoImage(data,size=300,type="multiple"){
		const defaultBanner = 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230613/7052e007942a1c1f16bc9fde16351652.png'
		if(type == 'single'){
			if(!data){
				data = defaultBanner
			}else{
				data = data.replace('https://cdn-xcx-qunsou.weiyoubot.cn', "https://image.xiaoqiandao.com") //这两种域名的图片都处理一下
				data = data.replace('https://cdn.xcxa2.com/xcx', "https://image.xiaoqiandao.com")
				data = data +'?imageMogr2/thumbnail/'+size+'x'
			}
			return data
		}else{
			if(data && Array.isArray(data)){				
				return data.map(item=>{	
					if(!item['banner']){
						item['banner'] = defaultBanner
					}else{
						item['banner'] = item['banner'].replace('https://cdn-xcx-qunsou.weiyoubot.cn', "https://image.xiaoqiandao.com") //这两种域名的图片都处理一下
						item['banner'] = item['banner'].replace('https://cdn.xcxa2.com/xcx', "https://image.xiaoqiandao.com")
						item['banner'] = item['banner']+'?imageMogr2/thumbnail/'+size+'x'
					}
					return item
				})
			}else{
				return []
			}
		}
		
	}

	// 获取当前日期的前几天或者后几天
	static getDate(current,num){
		var date = new Date(current);
		date.setDate(date.getDate() + num)	
		//当使用setDate()之后，date对象就发生了改变，所以之后getDate()就能获取到指定的日期
		//这里的num就是你要加的天数，减也可以。
		let year = date.getFullYear()				//年
		let month = date.getMonth() + 1				//月
		let day = date.getDate();					//天数
		if(month >= 1 && month <= 9) {
			month = `0${month}`
		}
		if(day >= 1 && day <= 9) {
			day = `0${day}`
		}
		return (new Date(`${year}/${month}/${day} 00:00:00`).getTime())/1000
	}

	// 把年月日拼接起来,然后转为时间戳，注：ios中只能用/
	static getFullDate(year,month,day=1,time='00:00:00'){
		return (new Date(year+'/'+(month+1)+'/'+day+' '+time).getTime())/1000
	}
	// 时间戳转为时间
	static getLocalTime(nS) {     
		return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
	}

	// 深拷贝
	static clone(obj) {
		let objClone = Array.isArray(obj) ? [] : {};
		if (obj && typeof obj === 'object') {
			for (let key in obj) {
				if (obj[key] && typeof obj[key] === 'object') {
					objClone[key] = this.clone(obj[key]);
				} else {
					objClone[key] = obj[key]
				}
			}
		}
		return objClone;
	}
	// 获取默认名称
	static getDefaultName(value){
		let name = value;
		if(!value || value == '匿名用户' || value == '微信用户'){
			name = '无昵称';
		}
		return name;
	}
	// 检测图片是否存在
	static checkImage(url) {
		return new Promise((resolve, reject) => {
			const img = new Image();		 
			img.onload = () => resolve(url);
			img.onerror = () => resolve();
			img.src = url;
		});
	}
	/*
		container：需要挂载水印的父级元素，默认是body,可根据实际情况传值
		content：水印的文本内容
		fillStyle：设置水印文字大小、颜色、透明度，默认0.013效果比较好
		width：canvas容器宽度
		height：canvas容器高度（注意，canvas容器的宽高决定了最后生成图片的尺寸）
		textBaseline：文字对齐方式
		rotate：文字旋转角度
		zIndex：挂载水印容器层级
	*/
	static canvasWM({   
		container = document.body,
		width ='100px',
		height = '100px',
		textAlign ='center',
		textBaseline ='middle',
		font ="12px microsoft yahei",
		fillStyle ='rgba(184, 184, 184, 0.013)',
		content ='16541654654654',
		rotate ='30',
		zIndex =1000
	}={}){
		let canvas = document.createElement('canvas');       
		canvas.setAttribute('width', width);
		canvas.setAttribute('height', height);
		let ctx = canvas.getContext("2d");       
		ctx.textAlign = textAlign;
		ctx.textBaseline = textBaseline;
		ctx.font = font;
		ctx.fillStyle = fillStyle;
		ctx.rotate(Math.PI / 180 * rotate);
		ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2);

		let base64Url = canvas.toDataURL();
		const watermarkDiv = document.createElement("div");
		watermarkDiv.setAttribute('style', `
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:${zIndex};
		pointer-events:none;
		background-repeat:repeat;
		background-image:url('${base64Url}')`);

		container.style.position = 'relative';
		container.insertBefore(watermarkDiv, container.firstChild);
	}
	
	// 文本写的水印
	static loadMark({
		content= "121311",
		watermark_x = 20,
		watermark_y = 20,
		rows = 0,
		cols = 0,
		x_space = 50,
		y_space = 50,
		color = '#000000',            
		opacity =  0.005,       
		fontSize = '16px',	
		width =  150,
		height = 100,
		rotate = 30,	
	}={}) {      		
		let oTemp = document.createDocumentFragment();
		let page_width = Math.max(document.body.scrollWidth, document.body.clientWidth);
		let page_height = Math.max(document.body.scrollHeight, document.body.clientHeight);	
		if (cols == 0 || (parseInt(watermark_x + width * cols + x_space * (cols - 1)) > page_width)) {
			cols = parseInt((page_width - watermark_x + x_space) / (width + x_space));
			x_space = parseInt((page_width - watermark_x - width * cols) / (cols - 1))
		}
		if (rows == 0 || (parseInt(watermark_y + height * rows + y_space * (rows - 1)) > page_height)) {
			rows = parseInt((y_space + page_height - watermark_y) / (height + y_space));
			y_space = parseInt(((page_height - watermark_y) - height * rows) / (rows - 1))
		}
		let x,y;		     
		for (let i = 0; i < rows; i++) {
			y = watermark_y + (y_space + height) * i;
			for (let j = 0; j < cols; j++) {
				x = watermark_x + (width + x_space) * j;
				let mask_div = document.createElement('div');
				mask_div.id = 'mask_div' + i + j;
				mask_div.appendChild(document.createTextNode(content));
				mask_div.style.webkitTransform = "rotate(-" + rotate+ "deg)";
				mask_div.style.MozTransform = "rotate(-" + rotate + "deg)";
				mask_div.style.msTransform = "rotate(-" + rotate+ "deg)";
				mask_div.style.OTransform = "rotate(-" + rotate + "deg)";
				mask_div.style.transform = "rotate(-" + rotate+ "deg)";
				mask_div.style.visibility = "";
				mask_div.style.position = "absolute";
				mask_div.style.left = x + 'px';
				mask_div.style.top = y + 'px';
				mask_div.style.overflow = "hidden";
				mask_div.style.zIndex = "9";
				mask_div.style.pointerEvents = "none";
				mask_div.style.opacity = opacity;
				mask_div.style.fontSize = fontSize;				
				mask_div.style.color = color;
				mask_div.style.textAlign = "center";
				mask_div.style.width = width + 'px';
				mask_div.style.height = height + 'px';
				mask_div.style.display = "block";			            
				oTemp.appendChild(mask_div)
			}
		};
		document.body.appendChild(oTemp)        
	}
}

